import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import "./styles.css";
import { pickRandomWords, calculateWPM, calculateAcc } from "./util";
import WordList from './components/WordList'
import NumWordsSelection from './components/NumWordsSelection'
import StatDisplay from './components/StatDisplay'
import ThemePicker from "./components/ThemePicker";
const ESC_KEYCODE = 27;
const SPACE_KEYCODE = 32;

const NUM_WORDS_STORAGE_KEY = 'CURRENT_NUMBER_OF_WORDS'

export default function App({ handleChangeTheme, themes }) {
  const [numWords, setNumWords] = useState(Number(localStorage.getItem(NUM_WORDS_STORAGE_KEY)) || 5);
  const [currentInputWord, setCurrentInputWord] = useState(" ");
  const [currentWord, setCurrentWord] = useState("");

  const [currentWordList, setCurrentWordList] = useState(
    pickRandomWords(numWords)
  );
  const [currentInputList, setCurrentInputList] = useState([]);

  const [time, setTime] = useState(0);
  const [wpm, setWpm] = useState(0);
  const [acc, setAcc] = useState(0);


  const [isShowingThemePicker, setIsShowingThemePicker] = useState(false)

  const firstStroke = useRef(true);

  const interval = useRef(0);

  useEffect(() => {
    localStorage.setItem(NUM_WORDS_STORAGE_KEY, numWords);
  }, [numWords]);

  useEffect(() => {
    setCurrentWordList(pickRandomWords(numWords));
  }, [numWords]);

  useEffect(() => {
    if (currentInputList.length === currentWordList.length) {
      stopTimer();
      setWpm(calculateWPM(currentWordList, currentInputList, time));
      setAcc(calculateAcc(currentWordList, currentInputList));
      return;
    }
  }, [currentInputList, currentWordList, time]);


  useEffect(() => {
    setCurrentWord((prevWord) => {
      if (!prevWord) return currentWordList[0];
      else return currentWordList[currentWordList.indexOf(prevWord) + 1];
    });
  }, [currentWordList, currentInputList]);

  useEffect(() => {
    if (
      currentInputList.length === currentWordList.length - 1 &&
      currentWordList[currentWordList.length - 1] === currentInputWord
    ) {
      setCurrentInputList((prevList) => (
        [...prevList, currentInputWord.trim()]
      ));
    }
  }, [currentInputList, currentWordList, currentInputWord]);

  const handleChangeInputWord = ({ target }) => {
    if (firstStroke.current) {
      startTimer();
      firstStroke.current = false;
    }
    setCurrentInputWord(target.value);
  };

  const startTimer = () => {
    interval.current = setInterval(() => {
      setTime((prevTime) => prevTime + 100);
    }, 100);
  };

  const stopTimer = () => {
    clearInterval(interval.current);
  };
  const resetTest = () => {
    setCurrentInputList([]);
    setCurrentInputWord("");
    setCurrentWordList(pickRandomWords(numWords));
    setCurrentWord(currentWordList[0]);
    stopTimer();
    setTime(0);
    setWpm(0);
    setAcc(0);
    firstStroke.current = true;
  };



  const handleKeyDown = (event) => {
    if (event.keyCode === SPACE_KEYCODE) {
      setCurrentInputList((prevList) => (
        [...prevList, currentInputWord.trim()]
      ));
      setCurrentInputWord("");

    }
    if (event.keyCode === ESC_KEYCODE) {
      resetTest();
    }
  }

  const handleNumWordsChange = (num) => {
    resetTest();
    setNumWords(num);
  };




  const isVisuallyValidInput = !currentWord || !currentInputWord || currentWord.startsWith(currentInputWord.trim())

  return (
    <AppContainer>
      <NumWordsSelection handleNumWordsChange={handleNumWordsChange} currentNum={numWords} />
      <WordList currentWordList={currentWordList} currentInputList={currentInputList} currentWord={currentWord} />
      <TextInputContainer isValid={isVisuallyValidInput}>

        <TextInputBox
          value={currentInputWord.trim()}
          onChange={handleChangeInputWord}
          onKeyDown={handleKeyDown}
          isValid={isVisuallyValidInput}
          type="text"
        />
      </TextInputContainer>
      <RedoButton onClick={resetTest}>Reset</RedoButton>
      <StatDisplay time={time} wpm={wpm} acc={acc} />

      <ThemePicker isShowing={isShowingThemePicker} onToggleShow={() => setIsShowingThemePicker(!isShowingThemePicker)} themes={themes} handleChangeTheme={handleChangeTheme} />
    </AppContainer>
  );
}

const AppContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.bg};
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  padding-top: 2rem;
`;


const TextInputBox = styled.input`
  width: calc(100%);
  height: 100%;
  background-color: ${props => props.theme.bg};


  outline: none;
  border: none;
  border-radius: .5rem;
  color: ${props => props.theme.text};
  font-size: 2rem;
  text-align: center;


`;

const TextInputContainer = styled.div`
  width: 80%;
  height: 3rem;
  margin-bottom: 2rem;
  background-color: ${props => props.theme.bg};

  border: ${props => props.isValid ? '1px solid' + props.theme.text : '2px solid ' + props.theme.incorrect};
  border-radius: .5rem;
  color: ${props => props.theme.text};
  display: flex;
  overflow: hidden;
  position: relative;


`;
const RedoButton = styled.button`
  outline: none;
  text-align: center;
  border: 1px solid transparent;
  border-radius:  .5rem  ;
  padding: 1.1rem 1.6rem;
  margin-bottom: 1rem;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 600;
  color: ${props => props.theme.bg};
  background-color: ${props => props.theme.highlight};
  cursor: pointer ;


`