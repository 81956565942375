import words from "./data/words.js";


export function pickRandomWords(num) {
  // Shuffle array
  const shuffled = words
    .map((word) => word.trim())
    .sort(() => 0.5 - Math.random());

  // Get sub-array of first n elements after shuffled
  return shuffled.slice(0, num);
}

export function calculateWPM(wordList, inputList, secs) {
  let mins = secs / 60 / 1000;
  let correct = inputList.filter(
    (word, i) => word.trim() === wordList[i].trim()
  );
  return Math.floor(correct.join(" ").trim().length / 5 / mins);
}
export function calculateAcc(wordList, inputList) {
  let correct = inputList
    .filter((word, i) => word.trim() === wordList[i].trim())
    .join(" ")
    .trim();
  let total = wordList.join(" ").trim();

  return Math.floor((correct.length / total.length) * 100);
}