import React from 'react'
import styled from "styled-components";

export default function WordList({ currentWordList, currentInputList, currentWord }) {
    return (
        <WordListContainer>
            {currentWordList.map((word, i) => (
                <span key={i}>
                    <ListWord
                        key={i}
                        isCurrent={currentInputList.length === i}
                        isCompleted={Boolean(currentInputList[i]) || currentInputList[i] === ''}
                        isCorrect={
                            currentInputList[i] &&
                            currentInputList[i].trim() === currentWordList[i]
                        }
                    >
                        {word}
                    </ListWord>{" "}
                </span>
            ))}
        </WordListContainer>
    )
}


const WordListContainer = styled.div`
  width: 80%;
  padding: 1rem;
  border: 1px solid ${props => props.theme.text};
  border-radius: .5rem;

  margin-bottom: 1rem;
  text-align: center;
  color: ${props => props.theme.text};
`;
const ListWord = styled.span`
  color: ${({ theme, isCorrect, isCompleted, isCurrent }) =>
        isCurrent ? theme.bg : isCompleted ? (isCorrect ? theme.correct : theme.incorrect) : theme.text};
  background-color: ${({ theme, isCurrent }) => isCurrent && theme.highlight};
`;
