import React from 'react'
import styled from "styled-components";



export default function ThemePicker({ themes, handleChangeTheme, isShowing, onToggleShow }) {
    const handleThemeChange = (theme) => {
        handleChangeTheme(theme)
    }
    return (
        <Container>
            <h3>
                Themes{' '}
                <ExpandButton onClick={onToggleShow}>{isShowing ? '-' : '+'}</ExpandButton>
            </h3>
            {isShowing && themes.map((theme, i) => (
                <ThemeItem key={i} onClick={() => handleThemeChange(theme)}>
                    {theme.name}
                    <ColorBoxContainer>
                        <ColorBox color={theme.bg} />
                        <ColorBox color={theme.highlight} />
                        <ColorBox color={theme.correct} />
                        <ColorBox color={theme.incorrect} />

                    </ColorBoxContainer>
                </ThemeItem>
            ))}
        </Container>
    )
}

const Container = styled.ul`
display: flex;
flex-direction: column;
margin-top: 4rem;
color: ${props => props.theme.text};
text-align: center;
margin-top: auto;
margin-bottom: 5rem;


`
const ExpandButton = styled.span`
font-size: 2rem;
pointer: cursor;
`

const ThemeItem = styled.li`
    list-style-type: none;
    display: block;
    margin-right: 1rem;
    margi-top: rem;
    cursor: pointer;
    text-decoration: underline;
    color: ${props => props.theme.text}
`
const ColorBoxContainer = styled.div`
    box-sizing: border-box;
    width: 2rem;
    min-height: 2rem;
    display: flex;
    flex-flow: row wrap;

`
const ColorBox = styled.div`
    width: 1rem;
    height: 1rem;
    margin: .01rem;
    background-color: ${props => props.color};
    border: 1px solid black;

`