import React from 'react'
import styled from "styled-components";


const options = [5, 10, 25, 50, 100, 200]
export default function NumWordsSelection({ handleNumWordsChange, currentNum }) {
    return (<Wrapper>
        {options.map((opt, i) => (
            <React.Fragment key={i}>
                <Option isCurrent={currentNum === opt} onClick={() => handleNumWordsChange(opt)}>
                    {opt}
                </Option>
                <span>
                    {options.length - 1 !== i && ' / '}
                </span>

            </React.Fragment>
        ))}
    </Wrapper>
    )
}

const Wrapper = styled.div`
  text-align: center;
  color: ${props => props.theme.text};
  margin-bottom: 3rem;
`
const Option = styled.span`
  cursor: pointer;
  text-decoration: ${props => props.isCurrent ? 'underline' : 'none'}
`