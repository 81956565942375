import React from 'react'
import styled from "styled-components";

export default function StatDisplay({ time, wpm, acc }) {
    return (
        <Wrapper>
            {/* <p>Time: {time / 1000}s</p> */}
            <p>WPM: {wpm}</p>
            <p>ACC: {acc}</p>
        </Wrapper>
    )
}

const Wrapper = styled.div`
text-align: center;
color: ${props => props.theme.text}

`