import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import themes from './data/themes.js'
import App from "./App";

const THEME_STORAGE_KEY = 'CURRENT_THEME'
function Index() {
  const [theme, setTheme] = useState(JSON.parse(localStorage.getItem(THEME_STORAGE_KEY)) || themes[0])
  useEffect(() => {
    localStorage.setItem(THEME_STORAGE_KEY, JSON.stringify(theme));
  }, [theme]);
  const handleChangeTheme = newTheme => {
    setTheme(newTheme)
  }
  return (
    <ThemeProvider theme={theme}>
      <App handleChangeTheme={handleChangeTheme} themes={themes} />
    </ThemeProvider>
  )
}

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
  rootElement
);
