export default [{
        name: 'default',
        bg: "#F1EDEE",
        text: "#3D5467",
        highlight: '#db5461',
        correct: "#6BF178",
        incorrect: "#EDAE49",
    },

    {
        name: 'dracula',
        bg: "#282a36",
        text: "#44475a",
        highlight: '#bd93f9',
        correct: "#50fa7b",
        incorrect: "#ff5555",
    },

]